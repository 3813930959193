* {
  box-sizing: border-box;
}

a {
  color: royalblue;
  text-decoration: none;
}

html {
  font-family: helvetica;
  font-size: 62.5%;
}

.active {
  background: rgba(48, 105, 167, 0.3);
}

.recovered {
  background: rgba(78, 167, 48, 0.3);
}

.deaths {
  background: rgba(167, 48, 48, 0.3);
}

.App {
  text-align: center;
  margin: 0 auto;
  width: 900px;
  padding: 10px;

  @media (max-width: 900px) {
    width: 100%;
  }

  header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .title {
      text-align: left;
      h1 {
        font-size: 2.6rem;
        margin: 0;
        padding: 0;
      }
      h5 {
        font-size: 1.6rem;
      }
    }

    .lang {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.4rem;

      select {
        margin: 0 0 0 10px;
      }
    }

    .subtitle {
      width: 100%;
      // border: 1px solid rgb(83, 126, 197);
      background-color: rgba(83, 126, 197, 0.2);
      padding: 10px 0;
    }
  }

  .single-country-header {
    background-color: #f8f2d9;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
    h2 {
      margin: 0;
    }
  }

  .single-country-subheader {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 0 0 0;
    & > h3 {
      width: 100%;
      text-align: left;
      font-size: 1.6rem;
      background-color: #f8f2d9;
      padding: 2rem;
    }
    .box {
      display: flex;
      flex-direction: column;
      width: 32.5%;
      padding: 10px;
      border-radius: 1rem;
      .title {
        font-size: 1.4rem;
        padding: 0 0 10px 0;
      }
      .total-number {
        font-size: 3.2rem;
        padding: 0 0 5px 0;
        font-weight: bold;
      }
      .new-number {
        font-size: 1.3rem;
      }
    }
  }

  .country-list {
    .country-list-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 15px 0;

      .country-list-title {
        font-size: 2rem;
        margin: 0;
        padding: 0;
        @media (max-width: 500px) {
          padding: 0 0 2rem 0;
        }
      }

      .search {
        @media (max-width: 500px) {
          width: 100%;
        }
        input,
        button {
          border: 1px solid #e1e1e2;
          padding: 1rem;
          @media (max-width: 500px) {
            width: 80%;
          }
        }

        button {
          border-left: 0;
          @media (max-width: 500px) {
            width: 20%;
          }
        }
      }
    }
    .country-list-table {
      width: 100%;
      position: "relative";

      .NarrowHeader {
        display: none;
      }

      @media (max-width: 600px) {
        .NewConfirmed,
        .NewDeaths,
        .NewRecovered {
          display: none;
        }
        .WideHeader {
          display: none;
        }
        .NarrowHeader {
          display: table-cell;
        }
      }

      thead {
        th {
          font-size: 1.3rem;
          position: sticky;
          top: 0;
          z-index: 10;
          background: #010038;
          color: white;

          @media (max-width: 600px) {
            font-size: 1.1rem;
            font-weight: normal;
          }

          &.sort {
            cursor: pointer;
            top: 32px;
          }
        }
        tr {
          height: 30px;
        }
      }
      tbody {
        td {
          font-size: 1.3rem;
          padding: 3px 0;
          @media (max-width: 800px) {
            font-size: 1.2rem;
          }
          @media (max-width: 500px) {
            font-size: 1rem;
          }
        }
        .country-table-row,
        .country-table-row-dark {
          &:hover {
            background: #e1e1e2 !important;
          }
        }
        .country-table-row-dark {
          background-color: #f1f1f2;
        }
        .country-name {
          font-size: 1.6rem;
          @media (max-width: 600px) {
            font-size: 1.3rem;
          }
          & > a {
            text-decoration: none;
            color: #293a80;
            font-weight: bold;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .new-confirmed {
          color: rgba(48, 105, 167, 1);
        }
        .new-deaths {
          color: rgba(167, 48, 48, 1);
        }
        .new-recovered {
          color: rgba(78, 167, 48, 1);
        }
      }
    }
  }
}

.summary {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 2.2rem;
  .summary-header {
    padding: 20px;
    margin: 0 0 2% 0;
    font-size: 3rem;
    font-weight: bold;
    background: rgba(167, 139, 48, 0.3);
    width: 100%;
    border-radius: 1rem;
    @media (max-width: 500px) {
      font-size: 2.2rem;
    }
  }
  .summary-body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 32%;
    @media (max-width: 800px) {
      width: 100%;
    }
    .summary-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      padding: 10px;
      width: 100%;
      margin: 0 0 3% 0;
      border-radius: 1rem;
      @media (max-width: 800px) {
        width: 32%;
      }

      p {
        margin: 0;
        padding: 5px 0;
        font-weight: bold;
        @media (max-width: 500px) {
          font-size: 1.7rem;
        }
        &:first-child {
          font-size: 1.6rem;
          font-weight: normal;
          @media (max-width: 500px) {
            font-size: 1.3rem;
          }
        }
      }
    }
  }

  .summary-chart {
    width: 66%;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
}

.smaller-charts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .chart-container {
    width: 49%;
    @media (max-width: 900px) {
      width: 100%;
    }
  }
}

th .countryName {
  font-size: 1.6rem;
}

footer {
  padding: 20px 0;
  font-size: 1.2rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  z-index: 11;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.5;
}
